$(document).ready(initPage);

function initPage() {
  $('.header-toggler').click(function () {
    $('.header-nav').toggleClass('active');
  });

  $('.faq-item .question').click(function () {
    $(this).parent().toggleClass('active');
  });

  $('.help-nav .help-cat-h').click(function () {
    $(this).parent().toggleClass('active');
  });

  $('.help-toggler').click(function () {
    $('.help-nav').toggleClass('active');
    $('.help-content').toggleClass('disabled');
  });

  $('.articles-block .group-h').click(function () {
    $(this).parent().toggleClass('active');
  });

  $('.custom-select-centered').select2({
    minimumResultsForSearch: Infinity,
    dropdownCssClass: 'select2-container--centered',
  });
}
